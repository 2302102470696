@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
}

.navbar:not(.navbar-ob) {
  @include box-shadow(0 1px 8px 0 rgba(0, 0, 0, 0.5));

  .nav-item {
    margin-right: 15px;
  }

  .nav-link img {
    margin-right: 5px;
  }

  .navbar-collapse:not(.show) {
    display: none;
  }

  button.navbar-toggler {
    border: none;
  }

  button+.navbar-brand {
    padding-right: 54px;
  }


}