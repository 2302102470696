@import "./variables";

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
}

.material-icons.md-24 {
  font-size: 24px !important;
  width: 32px !important;
  height: 32px !important;
}

.material-icons.md-36 {
  font-size: 36px !important;
  width: 36px !important;
  height: 36px !important;
}

.material-icons.md-48 {
  font-size: 48px !important;
  width: 48px !important;
  height: 48px !important;
}

.primary-icon {
  color: $petrol !important;
}

.text-light.primary-icon {
  color: $petrol !important;
}