.btn {
  font-size: 16px !important;
}

.btn-outline-primary {
  font-weight: bold;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-raised-button,
.mat-mdc-button-base,
.btn-outline-primary{
  border-radius: 0 !important;
}

button.par-card-button {
  white-space: normal;
  line-height: 36px !important;
  height: 36px !important;
}