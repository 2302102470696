@font-face {
  font-family: "VWAGTheSans-Regular";
  src: local("VWAGTheSans-Regular"), url(../../assets/fonts/VWAGTheSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "VWAGTheSans-Bold";
  src: local("VWAGTheSans-Bold"), url(../../assets/fonts/VWAGTheSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "VWAGTheSans-Italic";
  src: local("VWAGTheSans-Italic"), url(../../assets/fonts/VWAGTheSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "VWAGTheSans-Light";
  src: local("VWAGTheSans-Light"), url(../../assets/fonts/VWAGTheSans-Light.ttf) format("truetype");
}