$link-color: #253452;
$link-hover-color: currentColor;
$link-hover-decoration: underline;
$enable-shadows: true;
$theme-colors: (
    "primary": #253452,
    "danger": #d32f2f,
    "info": #757575,
    "warning": #FFA464,
);

$grid-breakpoints: (
    xs: 0, // handset portrait (small, medium, large) | handset landscape (small)
    sm: 600px, // handset landscape (medium, large) | tablet portrait(small, large)
    md: 960px, //  tablet landscape (small, large)
    lg: 1200px, // laptops and desktops
    xl: 1600px // large desktops
);

$container-max-widths: (
    sm: 600px,
    md: 960px,
    lg: 1200px,
    xl: 1600px
);

$light-blue: #F2F8FF;