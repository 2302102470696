@import './sk-colors';
@import './sk-design';
@import './variables';
@import './icons';
@import './typhography';
@import './misc';
@import './fonts';
@import './nav';
@import './buttons';
@import './transactions';
@import '../../assets/material-icon-fonts/material-icons.css';
@import './xs2a-overrides';
@import './rate-calculator';
@import '../shared/variables';

:root {
  --light-grey: #F2F2F2;
}