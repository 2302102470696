@import "./sk-colors";

.background-pending {
  background-color: #00253E !important;
}

.background-verify {
  background-color: #004666 !important;
}

.background-pending-fulfillment {
  background-color: #2B9AB5 !important;
}

.background-paid {
  background-color: #05CE9F !important;
}

.background-cancelled {
  background-color: #CD3B4F !important;
}

.background-timeout {
  background-color: #E0E0E0 !important;
}

.text-pending {
  color: #00253E !important;
}

.text-verify {
  color: #004666 !important;
}

.text-pending-fulfillment {
  color: #2B9AB5 !important;
}

.text-paid {
  color: #05CE9F !important;
}

.text-cancelled {
  color: #CD3B4F !important;
}

.text-timeout {
  color: #E0E0E0 !important;
}

.state-box-header {
  font-size: 21px;
  color: $white !important;
}