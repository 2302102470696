@use '@angular/material' as mat;
@import './sk-colors';

.custom-ngx-slider ngx-slider {
  .ngx-slider-pointer {
    background: #63DCE5;
  }

  .ngx-slider-tick {
    background: #A5A9AA;

    &.ngx-slider-selected {
      background: #A5A9AA;
    }
  }

  .ngx-slider-bar {
    background: #D8D8D8;
  }

}

.calc__details-line {
  color: #172134;
  opacity: .25;
}

.show-plans__company-address--colors {
  background: #164666;
  color: #FFFFFF;
}

.show-plans__bottom {
  background: mat.m2-get-color-from-palette($sk-grey, 100);
}

.calc__n-monthly-payments-value {
  color: #164666;
}

.calc__chevron--size {
  font-size: 36px;
}

.calc__details-1st-column span {
  color: #164666;
}

.calc__details-2nd-column span {
  color: #164666;
}

.show-plans__disclaimer span {
  color: #164666;
}

.calc__repay-in-value {
  color: #164666;
}

#calc-desired-amount-link {
  color: #164666;
}

.calc__repay-in-label {
  color: #164666;
}