.custom-ngx-slider ngx-slider {
  .ngx-slider-pointer {
    background: #ED812C;
  }

  .ngx-slider-tick {
    background: #9499A8;
    &.ngx-slider-selected {
      background: #9499A8;
    }
  }

  .ngx-slider-bar {
    background: #DEE0E4;
  }

}

.calc__details-line {
  color: #172134;
  opacity: .25;
}

.show-plans__company-address--colors {
  background: #3C4A64;
  color: #FFFFFF;
}

.show-plans__bottom {
  background: #F1F1EE;
}