@use '@angular/material' as mat;
@import "./variables";

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

#XS2A-Form {
  .xs2a-form-line {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  p {
    color: #757575 !important;
  }

  label {
    width: 100% !important;
    margin: 0.4em 0 !important;
    font-weight: inherit !important;
    font-size: 10px !important;
  }

  h3 {
    margin: 25px 0 !important;
  }

  .xs2a-description {
    color: #757575;
  }

  input,
  select {
    &:active, &:focus {
      border: 1px solid black !important;
    }

    @include border-radius(5px);
    border: 1px solid lightgrey;
    color: initial !important;
    width: 100% !important;
    background: none;
    padding: 10px 5px;
  }

  .xs2a-captcha {
    .xs2a-container {
      width: 100% !important;
    }
  }

  .xs2a-align-indent {
    margin-left: 0 !important;
  }

  .xs2a-checkbox,
  .xs2a-radio {
    input {
      margin-right: 5px !important;
      margin-top: 5px !important;
      width: auto !important;
    }
  }

  .xs2a-tabs {
    li.xs2a-selected {
      background: #fafafa;
    }
  }

  .xs2a-form-line.xs2a-error {
    padding: 5px !important;

  }

  .xs2a-error {
    p {
      color: #fafafa !important;
    }
  }

  button {
    width: 100%;
    padding: 8px 12px 6px 12px !important;
    @include border-radius(2px !important);
    text-transform: uppercase;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    font-weight: bolder !important;
    margin-left: 0 !important;
    border: 1px solid transparent;

    &:nth-child(n+1) {
      margin-top: 15px;
    }

    &.xs2a-abort, .xs2a-back {
      background: transparent !important;
      border: none !important;
      color: #424242;
    }

    &.xs2a-back {
      &:active, &:hover {
        background: none !important;
        border: none !important;
      }
    }

    &.xs2a-submit {
      background: $bright-blue !important;
      border-color: transparent !important;
      color: $petrol;

      &:active {
        background: $bright-blue !important;
        border-color: transparent !important;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }

      &:hover {
        background: $bright-blue !important;
        border-color: transparent !important;
      }

    }
  }

  .xs2a-lightbox {
    .xs2a-dialog {
      color: mat.m2-get-color-from-palette($sk-grey, 900);
    }
  }

  .xs2a-form-line {
    padding: 0 0 0.5em !important;
  }

}

