@use '@angular/material' as mat;
@import './variables';
@import './sk-colors';

.logo {
  background: url("../../assets/logos/logo_desktop.svg") 0 0 no-repeat padding-box;
  width: 230px;
  height: 138px;
  @media screen and (max-width: $phone) {
    background: url("../../assets/logos/logo_mobile.svg") 0 0 no-repeat padding-box;
    width: 165px;
    height: 47px;
  }
}

.p-0 {
  padding: 0 0 0 0;
}

.progress-bar {
  background-color: #006384;
}

.mat-expansion-panel-header {
  text-transform: uppercase;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: mat.m2-get-color-from-palette($sk-grey, 500) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: mat.m2-get-color-from-palette($sk-color, 500) !important;
  font-weight: bold !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-label {
  color: mat.m2-get-color-from-palette($sk-grey, 500) !important;
  font-weight: normal !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: white !important;
}

.mat-mdc-input-element {
  color: mat.m2-get-color-from-palette($sk-grey, 900) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value-text {
  color: mat.m2-get-color-from-palette($sk-grey, 900) !important;
}

.mat-mdc-option {
  color: mat.m2-get-color-from-palette($sk-grey, 900) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-outline-start, .mat-form-field-outline-end {
  border-radius: 0 !important;
}

.text-danger {
  color: $red-alert !important;
}

.card {
  &.card-gradient {
    border: none;
    background: mat.m2-get-color-from-palette($sk-grey, 200);
    color: #ffffff !important;

    a {
      color: mat.m2-get-color-from-palette($sk-color, 900) !important;

      &:hover, &:focus {
        color: #ffffff !important;
      }
    }
  }
}