* {
  &:active,
  :focus {
    outline: none !important;  // 1
  }
}

label {
  margin-bottom: 0; // 2
}

a:not(.mat-mdc-button):not(.mat-mdc-raised-button):not(.mat-mdc-fab):not(.mat-mdc-mini-fab):not([mat-list-item]):not(.nav-link) {
  color: #253452; // 3
}

body {
  font-family: 'Assistant', sans-serif !important;
  font-size: 14px;
}

button {
  text-transform: uppercase;
}

.mat-mdc-button, .mat-mdc-raised-button {
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: normal !important;
}